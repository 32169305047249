import React from "react"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import InlineHelp from "../../../../../components/inline-help"
import NotationGroup from "../../../../../components/notation-group"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

import PoemFruehlingNotation from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/poem-fruehling-notation"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/nomen/loesung" />
      }
    >
      <Seo title="Nomen" />
      <Stack>
        <Heading as="h1" level={2}>
          Nomen bestimmen
        </Heading>

        <TaskSidebar
          main={
            <PaperStack>
              <NotationGroup
                onNotationsUpdated={(notations) => {
                  setAnswer({
                    courseId: "jahreszeiten",
                    chapterId: "01-jahreszeitengedicht",
                    taskId: "nomen",
                    answer: notations,
                  })
                }}
              >
                <PoemFruehlingNotation />
              </NotationGroup>
            </PaperStack>
          }
          sidebar={
            <Stack space={6}>
              <Note variant="task">
                Markiere alle Nomen im Gedicht, indem du sie anklickst. Klicke
                erneut auf ein Wort, um die Markierung wieder zu entfernen.
              </Note>
              <InlineHelp title="Was sind nochmal Nomen?">
                Nomen sind sogenannte Hauptwörter. Sie haben einen Artikel (der,
                die, das) und werden in der Regel groß geschrieben.
              </InlineHelp>
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
